import styled from 'styled-components';

export default styled.div`
  padding: 148px 0 96px;
  @media (max-width: 768px) {
    padding: 64px 0;
  }
  .header-content {
    max-width: 770px;
  }
  h1 {
    @media (max-width: 768px) {
      font-size: 43.95px;
    }
  }
  h1,
  p {
    margin-bottom: 32px;
  }
  p {
    color: var(--subtle-text-color);
  }
  .primary-color {
    color: var(--primary-color);
  }

  .cta-wrapper {
    .btn-primary {
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    button {
      margin: 0 12px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 40px;
  }
`;
