import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Link from '../../../utils/Link';
import FaqHeroStyles from '../../../styles/Components/Hero/FaqHeroStyles';

const FaqHero = () => {
  return (
    <FaqHeroStyles>
      <Container>
        <div className="header-content mx-auto text-center">
          <h1>
            <span>Frequently Asked Questions</span>
          </h1>
          <div className="cta-wrapper">
            <Link to="/demo">
              <Button variant="primary">Schedule a Tour</Button>
            </Link>
          </div>
        </div>
      </Container>
    </FaqHeroStyles>
  );
};

export default FaqHero;
